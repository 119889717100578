import { Inject } from "@angular/core";
import { Component, OnInit, Input } from '@angular/core';
import { SharedAnimations } from '../../animations/shared-animations';
import { ImageService } from '../../services/image-upload.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

class ImageSnippet {
  pending: boolean = false;

  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './iu.component.html',
  styleUrls: ['./iu.component.scss'],
  animations: [SharedAnimations]
})
export class ImageUploadComponent implements OnInit {
  @Input() type:string;
  selectedFile: ImageSnippet = new ImageSnippet('', null);

  ngOnInit() {
    this.imageService.imageSetted.subscribe(res => {
      if(res){
        if(this.type.indexOf('thumb') < 0) {
          this.selectedFile['src'] = this.imageService.getImage;
        }
      }
    })

    this.imageService.imageThumbSetted.subscribe(res => {
      if (res) {
        if (this.type.indexOf('thumb') > 0) {
          this.selectedFile['src'] = this.imageService.getThumbImage;
        }
      }
    })
  }

  constructor(
    private imageService: ImageService,
    private toastr: ToastrService,
    private modalService: NgbModal) { }

  private onSuccess() {
    this.selectedFile.pending = false;
    this.toastr.success('', 'Resim başarıyla yüklendi.')
  }

  private onError() {
    this.selectedFile.pending = false;
    this.selectedFile.src = '';
    this.toastr.error('', 'Resim yükleme hatası.')
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);

      this.selectedFile.pending = true;
      this.imageService.imageType = this.type;
      this.imageService.uploadImage(this.type, this.selectedFile.file).subscribe(
        (res) => {
          this.onSuccess();
        },
        (err) => {
          this.onError();
        })
    });

    reader.readAsDataURL(file);
  }

  deleteImage(content) {
    this.modalService.open(content, { ariaLabelledBy: 'Emin Misin?', centered: true })
      .result.then((result) => {
        if (this.type.indexOf('thumb') > 0) {
          this.imageService.setThumbImage = '';
        }else {
          this.imageService.setImage = '';
        }
        this.selectedFile = new ImageSnippet('', null);;
        //this.toastr.success('', '', { timeOut: 2000 });
        }
      , (reason) => { });
  }
}
