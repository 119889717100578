import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    @Inject(DOCUMENT) private document: any,
    @Inject('panelUrl') private panelUrl
  ) { }

  async canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let isAuthenticated = await this.auth.checkAuth();
    
    if (isAuthenticated) {
      return true;
    } else {
      this.document.location.href = encodeURI(`https://www.glopark.com/login?continue=${this.panelUrl}${state.url}`);
    }
  }
}
