import { Injectable, Inject } from "@angular/core";
import { LocalStoreService } from "../../../shared/services/local-store.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../../../shared/services/auth.service';

@Injectable({
  providedIn: "root"
})
export class CustomerService {
  constructor(
    private store: LocalStoreService,
    @Inject('b2cApiUrl') private b2cApiUrl,
    private http: HttpClient,
    private authService: AuthService) { }

  list(skip, take, platform?, isShop?, excel?): Observable<any> {
    
    let list = {}
    list['FirmaGuid'] = this.authService.firmaGuid;
    list['Skip'] = skip;
    list['Take'] = take;

    if(platform) {
      list['Platform'] = platform;
    }

    if(isShop) {
      list['MobilUye'] = isShop;
    }

    if (excel) {
      list['Excel'] = excel;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(`${this.b2cApiUrl}/${list['FirmaGuid']}/musteri/list`, list, httpOptions)
      .pipe(map(res => {
        return res
      }))
  }
}
