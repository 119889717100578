import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGaurd } from './shared/guards/auth.gaurd';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';

const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: './views/dashboard/dashboard.module#DashboardModule'
    },
    {
      path: 'manager',
      loadChildren: './views/manager/manager.module#ManagerModule'
    },
    {
      path: 'urunler',
      loadChildren: './views/items/items.module#ItemsModule'
    },
    {
      path: 'entegre',
      loadChildren: './views/entegre/entegre.module#EntegreModule'
    },
    {
      path: 'b2b',
      loadChildren: './views/b2b/b2b.module#B2BModule'
    },
    {
      path: 'b2c',
      loadChildren: './views/b2c/b2c.module#B2CModule'
    },
    {
      path: 'pay',
      loadChildren: './views/pay/pay.module#PAYModule'
    }
  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
