import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    constructor() {
    }

    defaultMenu: IMenuItem[] = [
        {
            name: 'Rapor',
            description: '',
            type: 'extLink',
            icon: 'i-Bar-Chart-3',
            state: 'https://entegre.glopark.com'
        },
        {
            name: 'Siparişler',
            description: '',
            icon: 'i-Receipt',
            state: 'https://entegre.glopark.com/#!/siparis-ekrani',
            type: 'extLink'
        },

        {
            name: 'Ürünler',
            description: '',
            type: 'link',
            icon: 'i-Bar-Code',
            state: '/urunler/fiyat-listesi'
        },
        {
            name: 'Müşteriler',
            description: '',
            type: 'link',
            icon: 'i-Business-Mens',
            state: '/entegre/musteri-listesi',
        },
        {
            name: 'Pazaryeri',
            description: '',
            type: 'extLink',
            icon: 'i-Checkout-Bag',
            state: 'https://entegre.glopark.com/'   
            // sub: [

            //     { icon: 'i-ID-3', name: 'Kategori Eşleştir', state: '#', type: 'link' }
            // ]
        },
        {
            name: 'B2C',
            description: '',
            type: 'dropDown',
            icon: 'i-Business-Mens',
            sub: [

                { icon: 'i-Folders', name: 'Bannerlar', state: '/b2c/banners', type: 'link' },
                { icon: 'i-Folders', name: 'Koleksiyonlar', state: '/b2c/collections', type: 'link' },
                { icon: 'i-Assistant', name: 'Müşteri Hizmetleri', state: '/b2c/customer-services', type: 'link' },
                { icon: 'i-Statistic', name: 'Beden Rehberi', state: '/b2c/size-table', type: 'link' },
                { icon: 'i-Ticket', name: 'Kupon', state: '/b2c/coupon', type: 'link' },
                { icon: 'i-Bell', name: 'Bildirimler', state: '/b2c/push-notification', type: 'link' },
                { icon: 'i-Windows-2', name: 'Sayfalar', state: '/b2c/pages', type: 'link' }
            ]
        },
        {
            name: 'B2B',
            description: '',
            type: 'dropDown',
            icon: 'i-Computer-Secure',
            sub: [
                { icon: 'i-ID-3', name: 'Firma Modülü', state: '/b2b/firma-modulu', type: 'link' }
            ]
        },
        {
            name: 'PAY',
            description: '',
            type: 'dropDown',
            icon: 'i-Financial',
            sub: [
                { icon: 'i-Gear', name: 'Sanal Pos Ayarları', state: '/pay/sanal-pos-ayarlari', type: 'link' },
                { icon: 'i-Credit-Card', name: 'Kart Çekimi', state: '/pay/kart-cekimi', type: 'link' },
                { icon: 'i-Receipt-4', name: 'Kart İşlem Listesi', state: '/pay/islem-listesi', type: 'link' },
                { icon: 'i-Visa', name: 'Taksit Ayarları', state: '/pay/taksit-ayarlari', type: 'link' },
            ]
        },
        {
            name: 'Ayarlar',
            description: '',
            type: 'extLink',
            icon: 'i-Gear-2',
            state:'https://entegre.glopark.com/#!/api-bilgileri'
        }
    ];

    // defaultMenu: IMenuItem[] = [
    //     {   
    //         name: 'Dashboard',
    //         description: '',
    //         type: 'dropDown',
    //         icon: 'i-Bar-Chart',
    //         sub: [
    //             { icon: 'i-Clock-3', name: 'Glopark', state: '/dashboard', type: 'link' }
    //         ]
    //     },
    //     /* {
    //         name: 'Manager',
    //         description: '',
    //         type: 'dropDown',
    //         icon: 'i-Conference',
    //         sub: [

    //             { icon: 'i-ID-3', name: 'Lisans', state: '/manager/lisans', type: 'link' }
    //         ]
    //     }, */
    //     {
    //         name: 'Ürünler',
    //         description: '',
    //         type: 'dropDown',
    //         icon: 'i-Bar-Code',
    //         sub: [

    //             { icon: 'i-Money1', name: 'Ürün Fiyat Listesi', state: '/urunler/fiyat-listesi', type: 'link' }
    //         ]
    //     },
    //     {
    //         name: 'Entegre',
    //         description: '',
    //         type: 'dropDown',
    //         icon: 'i-Checkout',
    //         sub: [

    //             { icon: 'i-ID-3', name: 'Müşteri Listesi', state: '/entegre/musteri-listesi', type: 'link' }
    //         ]
    //     },
    //     {
    //         name: 'B2B',
    //         description: '',
    //         type: 'dropDown',
    //         icon: 'i-Computer-Secure',
    //         sub: [
    //             { icon: 'i-ID-3', name: 'Firma Modülü', state: '/b2b/firma-modulu', type: 'link' }
    //         ]
    //     },
    //     {
    //         name: 'B2C',
    //         description: '',
    //         type: 'dropDown',
    //         icon: 'i-Business-Mens',
    //         sub: [

    //             { icon: 'i-Folders', name: 'Bannerlar', state: '/b2c/banners', type: 'link' },
    //             { icon: 'i-Folders', name: 'Koleksiyonlar', state: '/b2c/collections', type: 'link' },
    //             { icon: 'i-Assistant', name: 'Müşteri Hizmetleri', state: '/b2c/customer-services', type: 'link' },
    //             { icon: 'i-Statistic', name: 'Beden Rehberi', state: '/b2c/size-table', type: 'link' },
    //             { icon: 'i-Ticket', name: 'Kupon', state: '/b2c/coupon', type: 'link' },
    //             { icon: 'i-Bell', name: 'Bildirimler', state: '/b2c/push-notification', type: 'link' }
    //         ]
    //     },
    //     {
    //         name: 'PAY',
    //         description: '',
    //         type: 'dropDown',
    //         icon: 'i-Financial',
    //         sub: [
    //             { icon: 'i-Gear', name: 'Sanal Pos Ayarları', state: '/pay/sanal-pos-ayarlari', type: 'link' },
    //             { icon: 'i-Credit-Card', name: 'Kart Çekimi', state: '/pay/kart-cekimi', type: 'link' },
    //             { icon: 'i-Receipt-4', name: 'Kart İşlem Listesi', state: '/pay/islem-listesi', type: 'link' },
    //             { icon: 'i-Visa', name: 'Taksit Ayarları', state: '/pay/taksit-ayarlari', type: 'link' },
    //         ]
    //     }
    // ];
//ManagerModule

    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
    // publishNavigationChange(menuType: string) {
    //   switch (userType) {
    //     case 'admin':
    //       this.menuItems.next(this.adminMenu);
    //       break;
    //     case 'user':
    //       this.menuItems.next(this.userMenu);
    //       break;
    //     default:
    //       this.menuItems.next(this.defaultMenu);
    //   }
    // }
}
