import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {
    storeList:Array<any>;
    store:any;
    detectStoreChange;
    constructor(
      private navService: NavigationService,
      public searchService: SearchService,
      private auth: AuthService
    ) { }
  
    ngOnInit() { 
        this.listStores();
    }

    listStores() {
      this.auth.listStores().subscribe(res => {
        if (res['status']) {
          this.storeList = res['data'].map((s) => { return { Id: s.Id, platformId: s.PlatformId, MagazaAdi: `${s.PlatformAdi.toUpperCase()} - ${s.MagazaAdi}` }});
          
          let tempStore = JSON.parse(localStorage.getItem('store'));
          if(tempStore) {
            this.store = tempStore;
            this.auth.detectStoreChange.next(tempStore.Id);
          }else {
            this.store = this.storeList[0];
            localStorage.setItem('store', JSON.stringify(this.storeList[0]));
            this.auth.detectStoreChange.next(this.storeList[0].Id);
          }
        }
      })
    }

  changeStore() {
    localStorage.setItem('store', JSON.stringify(this.store));
    if(this.store) {
      this.auth.detectStoreChange.next(this.store.Id);
    }
  }
  
  toggleSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      if (!state.sidenavOpen && !state.childnavOpen) {
          state.sidenavOpen = true;
          setTimeout(() => {
              state.childnavOpen = true;
          }, 50);
      }
    }
  
    signout() {
      this.auth.signout();
    }

}
