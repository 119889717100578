import { Injectable, Inject, EventEmitter } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ImageService {
    private image:string;
    public imageSetted: EventEmitter<boolean> = new EventEmitter();

    private imageThumb: string;
    public imageThumbSetted: EventEmitter<boolean> = new EventEmitter();
    public imageType:string;

    constructor(
        private http: HttpClient,
        @Inject('b2cApiUrl') private b2cApiUrl,
        private authService: AuthService) { }

    public uploadImage(uploadType:string, image: File): Observable<any> {
        const formData = new FormData();

        formData.append('file', image);
        formData.append('tip', uploadType);

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Accept': 'application/json'
            })
        }
        return this.http.post(`${this.b2cApiUrl}/${this.authService.firmaGuid}/image/upload`, formData, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    if(this.imageType.indexOf('thum') > 0) {
                        this.setThumbImage = res['url'];
                    }else {
                        this.setImage = res['url'];
                    }
                    return res;
                }else {
                    return {};
                }
            }));
    }

    public uploadFile(image: File): Observable<any> {
        const formData = new FormData();

        formData.append('file', image);

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Accept': 'application/json'
            })
        }
        return this.http.post(`${this.b2cApiUrl}/${this.authService.firmaGuid}/media-library/file/upload`, formData, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    return res;
                }else {
                    return {};
                }
            }));
    }

    //Firmanın tüm dosyalarının bilgisini getirir
    public getFile(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Accept': 'application/json'
            })
        }
        return this.http.get(`${this.b2cApiUrl}/${this.authService.firmaGuid}/media-library/file/get`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    return res;
                }else {
                    return {};
                }
            }));
    }

    set setImage(imageUrl) {
        console.log('image setted !');
        this.image = imageUrl;
        this.imageSetted.emit(true);
    }

    get getImage():string {
        return this.image;
    }

    set setThumbImage(imageUrl) {
        console.log('thumd setted !');
        this.imageThumb = imageUrl;
        this.imageThumbSetted.emit(true);
    }

    get getThumbImage(): string {
        return this.imageThumb;
    }
}
