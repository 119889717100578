import { Injectable, Inject } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root"
})
export class ProductService {
  apiId = this.cookie.get('FirmaGuid') == '4a85abcb-70ee-4c80-a7a2-9c543752d06c' ? 3 : 2;//this.apiId;

  constructor(
    private store: LocalStoreService,
    @Inject('b2cApiUrl') private b2cApiUrl,
    private authService: AuthService,
    @Inject('platformId') private platformId,
    @Inject('defaultSkip') private defaultSkip,
    @Inject('defaultTake') private defaultTake,
    private http: HttpClient,
    private cookie: CookieService) { }

  list(collectionId, search, handleUrl = ""): Observable<any> {
    let request = {};
    request['FirmaGuid'] = this.authService.firmaGuid;
    request['PlatformId'] = this.platformId;
    request['ApiId'] = this.apiId;
    request['KoleksiyonId'] = collectionId;
    request['Search'] = search;
    if (handleUrl) {
      request['HandleUrl'] = handleUrl;
    }
    // #region Local Storage içinden ApiID alınır
    var storeInLocalStorage = this.store.getItem('store');
    if (storeInLocalStorage) {
      if (storeInLocalStorage['Id']) {
        request['ApiId'] = storeInLocalStorage['Id'];
      }
    }
    // #endregion

    request['Skip'] = this.defaultSkip;
    request['Take'] = this.defaultTake;
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(`${this.b2cApiUrl}/${this.authService.firmaGuid}/mobil/product/list`, request, httpOptions)
      .pipe(map(res => {
        console.log("product", res);
        return res;
      }))
  }
}
