import { Injectable, Inject } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  authenticated = true;
  account = {};
  firm = {};
  firmaGuid;
  detectStoreChange = new BehaviorSubject(0);
  constructor(
    @Inject(DOCUMENT) private document: any,
    private store: LocalStoreService,
    private cookie: CookieService,
    private router: Router,
    @Inject('managerApiUrl') private managerApiUrl,
    @Inject('glopark') private glopark,
    @Inject('b2cApiUrl') private b2cApiUrl,
    private http: HttpClient,
    private toastr: ToastrService) { }

  checkAuth() {
    return new Promise((resolve, reject) => {
       //this.cookie.set('userloginkey', 'YTU2MjQyY2UtMGMxMy00Y2Q2LWE3MDItNDVjZmY2ZmQ3MDJk');
      let userPassCode = this.cookie.get('userloginkey'); 
      //alert(userPassCode)
      if (!userPassCode) {
        resolve(false);
      }else {
        this.getUser(userPassCode).subscribe(res => {
          if (res) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
    })
  }

  getUser(passKey):Observable<any> {
    let request = { data: passKey };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      })
    }
    return this.http.post(`${this.managerApiUrl}/GetUserLogin`, request, httpOptions)
      .pipe(map(res => {
        if (res['Sonuc']) {
          this.account = res['UyeBilgisi']; 
          this.firm = res['UyeFirmaBilgisi'].find(f => { return f.FirmaId == res['UyeAyarlari']['VarsayilanFirmaId']});
          this.firmaGuid = res['UyeFirmaBilgisi'][0].FirmaGuid;
          this.cookie.set('FirmaGuid', this.firmaGuid);

          //console.log(res)
          return true;
        } else {
          this.toastr.error('GLOPARK ile iletişime geçin.', 'Giriş başarısız!', { timeOut: 5000, closeButton: true, progressBar: true });
          return false;
        }
      }))
  }

  listStores(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
        'Content-Type': 'application/json'
      })
    }
    return this.http.get(`${this.b2cApiUrl}/${this.firmaGuid}/store/list`, httpOptions)
      .pipe(map(res => {
        return res;
      }))
  }

  loadAccount() {
    this.account = this.store.getItem('UyeBilgisi')
  }

  signout() {
    this.cookie.delete('userloginkey');
    this.document.location.href = encodeURI(`${this.glopark}/login`);
  }
}
