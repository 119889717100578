import { Injectable, Inject, EventEmitter } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class ImageV2Service {

    constructor(
        private http: HttpClient,
        @Inject('b2cApiUrl') private b2cApiUrl,
        private authService: AuthService) { }

    public uploadImage(uploadType:string, image: File): Observable<any> {
        const formData = new FormData();

        formData.append('file', image);
        formData.append('tip', uploadType);

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Accept': 'application/json'
            })
        }
        return this.http.post(`${this.b2cApiUrl}/${this.authService.firmaGuid}/image/upload`, formData, httpOptions)
            .pipe(map(res => {
                return res;
            }));
    }
}
