import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AddressService {
    constructor(
        private http: HttpClient,
        @Inject('b2cPureApiUrl') private b2cPureApiUrl
    ){ }

    listCities(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${this.b2cPureApiUrl}/sehir`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    return res['data'];
                } else {
                    return [];
                }
            }))
    }

    listTowns(cityId): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${this.b2cPureApiUrl}/ilce/${cityId}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    return res['data'];
                } else {
                    return [];
                }
            }))
    }

    listDistrict(townId): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${this.b2cPureApiUrl}/semt/${townId}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    return res['data'];
                } else {
                    return [];
                }
            }))
    }

    listQuarter(districtId): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic bW9iaW1ib19kZXY6YTV6eGZ0N1Ah',
                'Content-Type': 'application/json'
            })
        }
        return this.http.get(`${this.b2cPureApiUrl}/mahalle-koy/${districtId}`, httpOptions)
            .pipe(map(res => {
                if (res['status']) {
                    return res['data'];
                } else {
                    return [];
                }
            }))
    }
}