import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchModule } from './components/search/search.module';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { ImageService } from '../shared/services/image-upload.service';
import { ImageV2Service } from '../shared/services/image.service';
import { ProductService } from '../shared/services/product.service';
import { CookieService } from 'ngx-cookie-service';
import { CustomerService } from '../views/entegre/customer-list/customer.service';
import { AddressService } from '../shared/services/address';

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    SearchModule,
    ToastrModule.forRoot(),
    NgbModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule,
    RouterModule,
    FormsModule
  ]
})
export class SharedModule { 
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: 'apiId', useValue: 3 },
        { provide: 'platformId', useValue: 19 },
        { provide: 'defaultSkip', useValue: 0 },
        { provide: 'defaultTake', useValue: 5 },
        //{ provide: 'panelUrl', useValue: `http://localhost:4200/#` },
        { provide: 'glopark', useValue: `https://www.glopark.com` },
        { provide: 'panelUrl', useValue: `https://panel.glopark.com/#` },
        { provide: 'managerApiUrl', useValue: 'https://servis.glopark.com/GloparkManagerService.svc' },
        { provide: 'b2cApiUrl', useValue: `https://servis.glopark.com/api-b2c/api/b2c/firma` },
        { provide: 'b2cPureApiUrl', useValue: `https://servis.glopark.com/api-b2c/api/b2c` },
        { provide: 'b2bApiUrl', useValue: `https://servis.glopark.com/api-b2c/api/b2b/firma` },
        { provide: 'contactsApiUrl', useValue: `https://servis.glopark.com/api-contacts/api/firma` },
        { provide: 'itemsApiUrl', useValue: `https://servis.glopark.com/api-item/api/firma` },
        { provide: 'smsApiUrl', useValue: `https://servis.glopark.com/api-sms/api` },
        { provide: 'payApiUrl', useValue: `https://pay.glopark.com/` },
        { provide: 'UploadBanner', useValue: 'banners' },
        { provide: 'UploadCollection', useValue: 'collections' },
        { provide: 'UploadCollectionThumbnail', useValue: 'collections/thumbnail' },
        { provide: 'UploadCompanyLogo', useValue: 'company/logo' },
        { provide: 'pushPrefix', useValue: 'GPS' },
        ImageService, ImageV2Service, ProductService, CookieService, CustomerService, AddressService
      ]
    }
  }
}